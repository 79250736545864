<template>
    <div :class="[type === 'number' ? 'Clock' : 'mark']">
        <slot></slot>
    </div>
</template>
<script>
export default {
    name: 'ClockItem',
    props: {
        type: {
            type: String,
            default: 'number',
        },
    },
    methods: {
        startFlip() {
            this.$el.className = this.$el.className + ' animated flipInX';
            setTimeout(() => {
                this.$el.className = this.$el.className.replace(
                    / animated flipInX/,
                    ''
                );
            }, 900);
        },
    },
};
</script>
<style lang="scss" scoped>
.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
}
.Clock {
    font-family: 'OSP';
    width: 36px;
    height: 62px;
    background: rgba(255, 255, 255, 1);
		font-size: 56px;
		text-align: center;
    color: rgba(15, 38, 77, 1);
    line-height: 62px;
}

.mark {
    font-size: 56px;
    line-height: 56px;
    margin: 0 3px;
}
</style>
