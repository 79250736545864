<template>
    <div class="search-content">
        <div class="radio-button">
            <div class="btn-list" :style="{ paddingTop: top + 'px' }">
                <div v-for="item in searchTypes" :key="item.value" @click="searchType = item.value">
                    <el-button type="text" :class="{ active: searchType === item.value }">{{
                        $i18n.t(item.name)
                    }}</el-button>
                </div>
            </div>

            <img class="radio-img" src="../assets/sousuo_icon_sanjiao.png" alt="" :style="{ left: searchLeft }" />
        </div>

        <div class="searchIput">
            <el-autocomplete
                popper-class="my-autocomplete"
                v-model="searchVal"
                :fetch-suggestions="querySearch"
                :placeholder="$t('sou-suo-nin-xiang-yao-zhao-de-nei-rong')"
                @select="handleSelect"
                class="input-with-select"
                clearable
                @clear="clearInput"
                ref="search"
                :popper-append-to-body="false"
            >
                <template slot-scope="{ item }">
                    <div class="name" v-html="getImport(item, searchVal)"></div>
                </template>
            </el-autocomplete>
            <!-- <el-input
                :placeholder="$t('sou-suo-nin-xiang-yao-zhao-de-nei-rong')"
                v-model="searchVal"
                class="input-with-select"
                @change="goSearch"
                clearable
            >
            </el-input> -->
            <el-button type="warning" icon="el-icon-search" @click="goSearch"></el-button>
        </div>
    </div>
</template>

<script>
import { searchType } from '../utils/AppState';
import { matchingName } from '../utils/CommonApi';
export default {
    name: 'searchInput',
    data() {
        return {
            searchType: 'product',
            searchVal: '',
            searchTypes: [...searchType].map(item => {
                return {
                    value: item[0],
                    name: item[1]
                };
            }),
            shouldChange: true
        };
    },
    props: {
        top: {
            type: Number,
            default: 54
        },
        value: {
            type: Object,
            default: () => {
                return {};
            }
        },
        noSearch: {
            type: Boolean,
            default: false
        },
        isHome: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        this.$nextTick(() => {
            if (this.value.searchVal) {
                this.shouldChange = false;
                this.searchVal = this.value.searchVal;
                this.searchType = this.value.searchType;
                this.$nextTick(() => {
                    this.shouldChange = true;
                });
            }
        });
    },
    watch: {
        searchVal() {
            this.setValue();
        },
        searchType() {
            this.setValue();
        },
        $route() {
            console.log(this.$route.name);
            if (this.$route.name === 'home') {
                this.searchVal = '';
                this.searchType = 'product';
            }
        }
    },
    computed: {
        searchLeft() {
            return (
                this.searchTypes.findIndex(item => {
                    return item.value === this.searchType;
                }) *
                    (this.isChinese ? 68 : 90) +
                'px'
            );
        }
    },
    methods: {
        goSearch() {
            if (!this.noSearch) {
                this.$router.push({
                    name: 'search',
                    query: {
                        search: this.searchVal,
                        searchType: this.searchType
                    }
                });
            } else {
                this.$emit('search');
            }
        },
        setValue() {
            if (this.shouldChange) {
                this.$emit('input', {
                    searchVal: this.searchVal,
                    searchType: this.searchType
                });
            }
        },
        handleSelect(item) {
            this.searchVal = item;
            this.goSearch();
        },
        querySearch(queryString, cb) {
            console.log(queryString);
            var results = [];
            if (queryString) {
                matchingName(queryString, this.searchType).then(res => {
                    let searchstr = queryString.toLowerCase();
                    results = [
                        ...new Set(
                            res.map(item => {
                                if (
                                    (this.isChinese && item.chName.toLowerCase().indexOf(searchstr) !== -1) ||
                                    (!this.isChinese && item.enName.toLowerCase().indexOf(searchstr) === -1)
                                ) {
                                    return item.chName;
                                } else {
                                    return item.enName;
                                }
                            })
                        )
                    ];

                    cb(results);
                });
            } else {
                cb(results);
            }
        },
        clearInput() {
            this.$refs.search.$refs.input.blur();
            this.goSearch();
        }
    }
};
</script>
<style lang="scss" scoped>
@import '../styles/variables';
.search-content {
    width: 8.8rem;
    margin: auto;
}

.searchIput {
    display: flex;
    height: 56px;
    margin-top: 10px;

    .input-with-select {
        flex-grow: 1;
    }
    .el-button {
        min-width: 96px;
    }
}
.el-input {
    display: flex;
    align-items: center;
}
.el-input-group {
    height: 56px;
    margin-top: 10px;
}
.radio-button {
    position: relative;
    z-index: 2;
}
.radio-img {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 16px;
    height: 10px;
    transform: translate(26px, 12px);
    transition: left ease-in-out 0.3s;
}

.isEn{
    .radio-img {
         transform: translate(35px, 12px);
    }
}

.el-button + .el-button {
    margin-left: 0px;
}
.el-button--text {
    width: 68px;
    // padding: 0 10px;
    color: #acb3bf;

    &.active {
        color: #292c33;
    }
}

.isEn {
    .el-button--text {
        width: 90px;
    }
}

.btn-list {
    display: flex;
    padding: 56px 0 0;
}
</style>
