<template>
    <div class="home">
        <!-- banner -->
        <swiper id="banner" ref="banner" class="swiper banner" :options="bannerOPtions">
            <swiper-slide v-for="item in banners" :key="item.id">
                <auto-img :src="item.img" mul="36%" :path="item.url"></auto-img>
            </swiper-slide>
            <div
                class="swiper-pagination swiper-pagination-white"
                style="bottom: 30px; left: 0; right: 0"
                slot="pagination"
                v-siwperPagination
            ></div>
        </swiper>

        <div class="search">
            <search-input-home isHome></search-input-home>
        </div>

        <div class="hot">
            <title-driver class="hot-title">
                <span slot="title">{{ $t('re-men-chan-pin') }}</span>
                <span slot="sub">HOT PRODUCTS</span>
            </title-driver>

            <el-container class="hot-container" v-loading="hotLoading">
                <el-aside width="240px">
                    <div
                        class="aside-menu"
                        v-for="(item, index) in hotProductsList"
                        :key="index"
                        :class="{ active: index === activeIndex }"
                        @mouseenter="menuSelect(index)"
                    >
                        {{ item.name }}
                    </div>

                    <!-- <el-menu default-active="0" @select="menuSelect">
                        <el-menu-item
                            :index="`${index}`"
                            v-for="(item, index) in hotProductsList"
                            :key="index"
                        >
                            <span slot="title">{{ item.name }}</span>
                        </el-menu-item>
                    </el-menu> -->
                </el-aside>
                <el-container>
                    <el-main v-if="mainProductInfo">
                        <div
                            class="hot-img bg"
                            :style="{
                                backgroundImage: `url(${getList(
                                    nowHotProductInfo.img || mainProductInfo.img,
                                    '?x-oss-process=image/resize,m_fill,h_1000,h_1000'
                                )})`
                            }"
                        ></div>

                        <div ref="hotInfo" class="hot-info">
                            <div class="hot-info-top">
                                <div class="text1 fontYahei">
                                    {{ getName(mainProductInfo) }}
                                </div>
                                <div class="text2 fontYahei">
                                    {{ getName(mainProductInfo, ['model', 'enModel']) }}
                                </div>
                                <div class="text3">
                                    {{ getName(mainProductInfo, ['introduction', 'enIntroduction']) }}
                                </div>
                            </div>
                            <div class="btns">
                                <el-button @click="$router.push('/productDetail?id=' + mainProductInfo.id)"
                                    >{{ $t('cha-kan-xiang-qing') }} <i class="el-icon-right"></i
                                ></el-button>
                                <el-button class="zixun" type="warning" @click="advisory(mainProductInfo)">
                                    <div class="btn-content">
                                        <span class="iconfont">&#xe615;</span>{{ $t('kuai-su-xun-jia') }}
                                    </div>
                                </el-button>
                            </div>
                        </div>
                    </el-main>
                </el-container>
            </el-container>

            <div class="productShow" v-if="swiperProducts.length > 0">
                <swiper class="swiper2" ref="hotSwiper" :options="productOption">
                    <swiper-slide class="swiper-slide2" v-for="item in swiperProducts" :key="item.id">
                        <ProductGride :info="item" :isNew="item.isNew" isHome></ProductGride>
                    </swiper-slide>
                    <div class="swiper-pagination" slot="pagination" v-siwperPagination></div>
                </swiper>
            </div>
        </div>

        <!-- <div class="datas" ref="datas">
            <div
                class="datascontent bg"
                :style="{
                    backgroundImage: 'url(' + require('../assets/home_img_yewushuju.png') + ')'
                }"
            >
                <title-driver fontColor="#fff">
                    <span slot="title">{{ $t('ye-wu-shu-ju') }}</span>
                    <span slot="sub">DATA</span>
                </title-driver>

                <div class="datasList">
                    <div class="datas-item">
                        <div class="val">
                            <ICountUp ref="count1" :endVal="count1" :options="options" />
                        </div>
                        <div class="name">{{ $t('chan-pin-shu-liang') }}</div>
                    </div>
                    <div class="datas-item datas-center">
                        <div class="val">
                            <Clock :val="clockValue" />
                            <ICountUp
                                v-changeHtml="changeDataCenter"
                                ref="count3"
                                :endVal="count3"
                                :options="options2"
                                style="opacity:0;position:absolute"
                            />
                        </div>
                        <div class="name">{{ $t('mao-yi-e') }}</div>
                    </div>
                    <div class="datas-item">
                        <div class="val">
                            <ICountUp ref="count2" :endVal="count2" :options="options" />
                        </div>
                        <div class="name">{{ $t('ke-hu-shu-liang') }}</div>
                    </div>
                </div>
            </div>
        </div> -->

        <div class="partner">
            <title-driver class="partner-title">
                <span slot="title">{{ $t('he-zuo-huo-ban') }}</span>
                <span slot="sub">PARTNER</span>
            </title-driver>

            <swiper ref="partner" class="partner-swiper" :options="partnerOption">
                <swiper-slide v-for="item in vendorInfos" :key="item.id" class="partner-slide">
                    <PartnerGride :info="item"></PartnerGride>
                </swiper-slide>
                <div class="swiper-pagination" slot="pagination" v-siwperPagination></div>
            </swiper>
        </div>

        <div class="information">
            <title-driver>
                <span slot="title">{{ $t('hang-ye-zi-xun') }}</span>
                <span slot="sub">INFORMATION</span>
            </title-driver>

            <swiper class="information-swiper" :options="informationOption">
                <swiper-slide v-for="item in informations" :key="item.id">
                    <NewsGride :info="item"></NewsGride>
                </swiper-slide>
                <div class="swiper-pagination" slot="pagination" v-siwperPagination></div>
            </swiper>
        </div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import ProductGride from '../components/product/Gride';
import PartnerGride from '../components/brand/PartnerGride';
import NewsGride from '../components/news/Gride';
import ICountUp from 'vue-countup-v2';
import Clock from '../components/Clock';
import 'swiper/css/swiper.css';
import { getBanner } from '../utils/CommonApi';
import { animateCSS } from '../utils/Animate';
import SearchInputHome from '../components/SearchInputHome';
import UserStoreOperation from '../mixins/UserStoreOperation';
const observerMap = new Map();
export default {
    name: 'Home',
    mixins: [UserStoreOperation],
    data() {
        return {
            banners: [],
            bannerOPtions: {
                autoplay: {
                    delay: 3000,
                    stopOnLastSlide: false,
                    disableOnInteraction: false
                },
                speed: 600,
                preventClicks: false,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    bulletClass: 'banner-bullet',
                    bulletActiveClass: 'banner-bullet-active'
                }
            },
            productOption: {
                autoplay: {
                    delay: 3000,
                    stopOnLastSlide: false,
                    disableOnInteraction: false
                },
                preventClicks: false,
                slidesPerView: 'auto',
                spaceBetween: 30,
                loop: false,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    bulletClass: 'progress-bullet',
                    bulletActiveClass: 'progress-bullet-active'
                }
            },
            partnerOption: {
                slidesPerView: 4,
                slidesPerGroup: 4,
                slidesPerColumn: 2,
                // spaceBetween: 30,
                slidesPerColumnFill: 'row',
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    bulletClass: 'content-bullet',
                    bulletActiveClass: 'content-bullet-active'
                },
                breakpoints: {
                    1720: {
                        //当屏幕宽度大于等于320
                        slidesPerView: 6,
                        slidesPerGroup: 6,
                        slidesPerColumn: 1
                    },
                    1450: {
                        //当屏幕宽度大于等于768
                        slidesPerView: 5,
                        slidesPerGroup: 5,
                        slidesPerColumn: 2
                    },
                    1280: {
                        //当屏幕宽度大于等于1280
                        slidesPerView: 4,
                        spaceBetween: 4,
                        slidesPerColumn: 2
                    }
                }
            },
            informationOption: {
                slidesPerView: 'auto',
                spaceBetween: 30,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    // type: 'progressbar',
                    bulletClass: 'progress-bullet',
                    bulletActiveClass: 'progress-bullet-active'
                }
            },
            options: {
                useEasing: true,
                useGrouping: true,
                separator: ',',
                decimal: '.',
                prefix: '',
                suffix: ''
            },
            options2: {
                decimalPlaces: 2,
                useEasing: true,
                useGrouping: true,
                separator: ',',
                decimal: '.',
                prefix: '',
                suffix: ''
            },
            hotProducts: [],
            activeIndex: 0,
            vendorInfos: [],
            informations: [],
            hotLoading: true,
            count1: 0,
            count2: 0,
            count3: 0,
            timeData: null,
            clockValue: 0
        };
    },
    computed: {
        partnerSwiper() {
            return this.$refs.partner.$swiper;
        },
        hotProductsList() {
            return [...this.hotProducts].map(item => {
                if (this.$i18n.locale.indexOf('zh') !== -1) {
                    return { name: item.chName, img: item.img, products: item.products, mainProduct: item.mainProduct };
                } else {
                    return { name: item.enName, img: item.img, products: item.products, mainProduct: item.mainProduct };
                }
            });
        },
        nowHotProductInfo() {
            const allList = [...this.hotProductsList];
            return allList[Number(this.activeIndex)] ? allList[Number(this.activeIndex)] : {};
        },
        nowProductsList() {
            const allList = [...this.hotProductsList];
            const list = allList[Number(this.activeIndex)] ? [...allList[Number(this.activeIndex)].products] : [];
            return list.map(item => {
                return {
                    ...item,
                    name: this.$i18n.locale.indexOf('zh') !== -1 ? item.chName : item.enName
                };
            });
        },
        mainProductInfo() {
            return { ...this.nowHotProductInfo }.mainProduct;
        },
        swiperProducts() {
            return [...this.nowProductsList];
        }
    },
    beforeRouteLeave(to, from, next) {
        if (['brandDetail', 'productDetail', 'NewsDetail'].includes(to.name)) {
            this.$store.commit('updateKeepAlive', ['Home']);
        } else {
            this.$store.commit('updateKeepAlive', []);
        }
        setTimeout(() => {
            next();
        }, 100);
    },
    directives: {
        // 使用局部注册指令的方式
        changeHtml: {
            // 指令的名称
            bind(el, binding) {
                const config = { attributes: true, childList: true, subtree: true };
                const callback = function (mutationsList, observer) {
                    if (mutationsList.length > 0) {
                        if (mutationsList[0].type === 'childList' && mutationsList[0].addedNodes[0].data) {
                            binding.value(mutationsList[0].addedNodes[0].data);
                        }
                    }
                };

                var _observer = new MutationObserver(callback);
                _observer.observe(el, config);
                observerMap.set(el, _observer);
            },
            unbind(el) {
                observerMap.get(el).disconnect();
            }
        }
    },
    components: {
        Swiper,
        SwiperSlide,
        ProductGride,
        ICountUp,
        Clock,
        PartnerGride,
        NewsGride,
        SearchInputHome
    },
    watch: {
        isChinese() {
            this.$nextTick(() => {
                getBanner('HOME', !this.isChinese).then(res => {
                    this.banners = res;
                    this.$nextTick(() => {
                        this.$refs.banner.$swiper.update();
                    });
                });
            });
        }
    },
    mounted() {
        this.$nextTick(() => {
            getBanner('HOME', !this.isChinese).then(res => {
                this.banners = res;
                this.$nextTick(() => {
                    this.$refs.banner.$swiper.update();
                });
            });
        });

        this.$http.get('/hotProduct/show').then(res => {
            this.hotLoading = false;
            this.hotProducts = res;
            this.$nextTick(() => {
                this.$refs.hotSwiper.$swiper.update();
            });
        });

        this.$http
            .post(
                '/information/all',
                {
                    query: {
                        status: 'PASS',
                        isHomeShow: true
                    },
                    sort: 'auditTime,desc'
                },
                { body: 'json' }
            )
            .then(res => {
                this.informations = res.content;
            });
        this.$http.get('/vendorInfo/home').then(res => {
            this.vendorInfos = res;
        });

        // window.addEventListener('scroll', this.scrollEvent);
    },
    methods: {
        goDetail(id) {
            console.log(id);
        },
        menuSelect(key) {
            if (this.activeIndex !== key) {
                this.activeIndex = key;
                this.$nextTick(() => {
                    this.$refs.hotSwiper.$swiper.update();
                    this.$refs.hotSwiper.$swiper.slideTo(0, 1000, true);
                    animateCSS(this.$refs.hotInfo, 'slideInRight');
                });
            }
        },
        sizeChange() {
            // if (window.innerWidth >= 1720) {
            //     this.partnerSwiper.params.slidesPerView = 6;
            //     this.partnerSwiper.update();
            // } else if (window.innerWidth >= 1450) {
            //     this.partnerSwiper.params.slidesPerView = 5;
            //     this.partnerSwiper.update();
            // } else if (window.innerWidth >= 1280) {
            //     this.partnerSwiper.params.slidesPerView = 4;
            //     this.partnerSwiper.update();
            // }
        },
        goSearch() {
            this.$router.push({
                name: 'search',
                query: {
                    search: this.searchVal,
                    searchType: this.searchType
                }
            });
        },
        goNext(path) {
            console.log(path);
        },
        scrollEvent() {
            if (
                document.documentElement.scrollTop + document.documentElement.clientHeight / 2 >=
                this.$refs.datas.offsetTop
            ) {
                if (!this.count1) {
                    this.count1 = 4632;
                }
                if (!this.count2) {
                    this.count2 = 2487;
                }
                if (!this.count3) {
                    this.count3 = 564632.7;
                }
            } else {
                this.count1 = 0;
                this.count2 = 0;
                this.count3 = 0;
            }
        },
        changeDataCenter(value) {
            if (value != this.clockValue) {
                this.clockValue = value;
            }
        }
    }
};
</script>
<style lang="scss" scoped>
@import '../styles/variables';
.parallax-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 130%;
    height: 100%;
    background-size: cover;
    background-position: left;
}

.swiper {
    position: relative;
    z-index: 1;
    height: 6.9rem;

    .bannerImg {
        width: 100%;
        height: 0;
        padding-top: 36%;
    }

    .swiper-pagination {
        bottom: 75px;
    }
}
.search {
    position: relative;
    z-index: 2;
    // height: 105px;
}

.swiper2 {
    height: 380px;
    padding: 30px 0 50px;
    // box-sizing: border-box;

    .swiper-slide2 {
        width: 240px;
    }

    .swiper-pagination {
        top: 450px;
    }
}

.hot {
    padding: 20px $--page-padding 80px;
    box-sizing: border-box;
    width: 100%;
    max-width: 1690px;
    margin: 0 auto;
    .el-aside {
        padding: 30px 0;
        background-color: $--color-primary;
    }

    .el-main {
        overflow: visible;
    }
    .el-menu {
        border-right-width: 0px;
    }
    .el-menu-item {
        // padding: 0 28px;
        padding-left: 28px !important;
        &.is-active {
            color: $--color-text-primary;
            position: relative;
            background-color: mix($--color-black, $--color-primary, $--button-active-shade-percent);

            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                width: 4px;
                background-color: $--color-text-primary;
            }
        }
    }

    .hot-title {
        padding: 50px 50px 64px;
    }

    .el-main {
        padding: 0 0 0 30px;
        position: relative;
    }

    .hot-img {
        width: 59%;
        height: 440px;
    }

    .hot-info {
        position: absolute;
        right: 0;
        background-color: #fff;
        height: 360px;
        width: 46%;
        padding: 40px 36px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        top: 40px;

        box-shadow: -2px 0px 6px 0px rgba(0, 0, 0, 0.04);
        .hot-info-top {
            flex-grow: 1;
        }

        .text1 {
            font-size: $--font-size-2xl;
            color: $--color-text-regular;
            line-height: 24px;
            font-weight: bold;
        }
        .text2 {
            font-size: $--font-size-xl;
            color: $--color-text-regular;
            line-height: 24px;
            font-weight: bold;
            margin-top: 10px;
        }
        .text3 {
            font-size: $--font-size-sm;
            color: $--color-text-regular;
            line-height: 26px;
            margin-top: 19px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
        }

        .el-button--default {
            background-color: #dcdfe6;
            border-color: #dcdfe6;
            color: #565b66;
            min-width: 24%;
            line-height: 44px;
            height: 44px;
            padding: 0 12px;
            &:hover {
                background-color: mix($--color-black, #dcdfe6, $--button-active-shade-percent);
                border-color: mix($--color-black, #dcdfe6, $--button-active-shade-percent);
            }
        }

        .el-button--warning {
            min-width: 36%;
        }

        .el-button + .el-button {
            margin-left: 16px;
        }
    }
}

.datas {
    background-color: $--color-primary;
    padding: 47px 0 35px;

    .datascontent {
        width: 891px;
        height: 485px;
        margin: auto;
        box-sizing: border-box;
        padding: 30px 30px 90px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .datasList {
        display: flex;
        margin-top: 110px;

        .datas-item {
            color: $--color-white;
            display: flex;
            flex-direction: column;
            align-items: center;
            min-width: 100px;

            .val {
                font-family: 'OSP';
                font-size: 56px;
                color: rgba(255, 255, 255, 1);
                line-height: 56px;
            }

            .name {
                font-size: 16px;
                line-height: 22px;
                margin-top: 19px;
            }
        }

        .datas-center {
            flex-grow: 1;
        }
    }
}

.partner {
    // padding: 140px 35px 40px;
    // box-sizing: border-box;
    // width: 100%;
    // max-width: 1690px;
    // margin: auto;
    @include gride-content();
    padding-top: 140px;
    padding-bottom: 40px;
}

.partner-swiper {
    padding: 70px 0;
    position: relative;
    box-sizing: border-box;

    .swiper-slide {
        padding: 15px;
        box-sizing: border-box;
    }
}
.information {
    padding: 70px 0 100px;
}
.information-swiper {
    padding-top: 70px;
    padding-right: $--page-padding + 20;
    padding-bottom: 100px;
    padding-left: $--page-padding;
    .swiper-slide {
        width: 374px;
    }

    .swiper-pagination-progressbar {
        top: 560px;
    }
}

@media screen and (min-width: 1690px) {
    .information-swiper {
        padding-left: calc(50% - 795px);
    }
}

.hot-container {
    position: relative;
    z-index: 1;
}

.aside-menu {
    font-size: 14px;
    color: #ffffff;
    line-height: 50px;
    padding: 0 32px;
    cursor: pointer;
    position: relative;

    &.active {
        font-weight: bold;
        color: #ffa526;
        background-color: rgba(0, 0, 0, 0.2);
        &::before {
            content: '';
            width: 4px;
            height: 50px;
            background: #ffa526;
            position: absolute;
            left: 0;
            top: 0;
        }
    }
}
.aside-menu + .aside-menu {
    margin-top: 5px;
}

.btns {
    display: flex;
    align-items: center;
    .el-button {
        font-size: 12px;
    }
}

.zixun {
    .btn-content {
        font-size: 12px;

        .iconfont {
            font-size: 22px;
            padding: 0 6px;
        }
    }
}

.isEn {
    .hot-container {
        .el-aside {
            .aside-menu {
                padding: 0 20px;
                font-size: 12px;
            }
        }
    }

    .hot .hot-info .text1 {
        font-size: 18px;
    }
}
</style>
