var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('router-link',{staticClass:"news-info",attrs:{"to":{
        name: 'NewsDetail',
        query: {
            id: _vm.info.id,
            type: _vm.type
        }
    }}},[_c('el-card',{class:[_vm.shadow ? 'shadow' : 'border'],attrs:{"body-style":{ padding: '0px' }}},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"img bg",style:({
                    backgroundImage: ("url(" + (_vm.info.img) + ")")
                })})]),_c('div',{staticClass:"info"},[_c('div',{staticClass:"name"},[(_vm.isList && _vm.info.top)?_c('el-tag',{attrs:{"size":"mini","effect":"dark"}},[_vm._v(_vm._s(_vm.$t('zhi-ding')))]):_vm._e(),_c('span',{domProps:{"innerHTML":_vm._s(_vm.getImport(_vm.info.title))}})],1),_c('div',{staticClass:"sub"},[_vm._v(" "+_vm._s(_vm.info.introduction)+" ")]),_c('div',{staticClass:"time"},[_c('span',[_vm._v(_vm._s(_vm.info.createdAt))]),_c('img',{attrs:{"src":require("../../assets/icon_more.png"),"alt":""}})])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }