<template>
    <router-link
        class="news-info"
        :to="{
            name: 'NewsDetail',
            query: {
                id: info.id,
                type: type
            }
        }"
    >
        <el-card :class="[shadow ? 'shadow' : 'border']" :body-style="{ padding: '0px' }">
            <div class="top">
                <div
                    class="img bg"
                    :style="{
                        backgroundImage: `url(${info.img})`
                    }"
                ></div>
            </div>
            <div class="info">
                <div class="name">
                    <el-tag v-if="isList && info.top" size="mini" effect="dark">{{ $t('zhi-ding') }}</el-tag>
                    <span v-html="getImport(info.title)"></span>
                    <!-- {{ info.title }} -->
                </div>
                <div class="sub">
                    {{ info.introduction }}
                </div>

                <div class="time">
                    <span>{{ info.createdAt }}</span>
                    <img src="../../assets/icon_more.png" alt="" />
                </div>
            </div>
        </el-card>
    </router-link>
</template>
<script>
export default {
    name: 'Gride',
    props: {
        shadow: {
            type: Boolean,
            default: false
        },
        info: {
            type: Object,
            default: () => {
                return {};
            }
        },
        search: {
            type: String,
            default: ''
        },
        isList: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        type() {
            return this.$route.query.type || '';
        }
    },
    methods: {
        goDetail() {
            this.$router.push('/NewsDetail?id=' + this.info.id);
        }
    }
};
</script>
<style lang="scss" scoped>
@import '../../styles/variables';
.news-info {
    display: block;
}
.el-card {
    transition: all ease-in-out 0.3s;
    box-shadow: none;
    position: relative;
    // border-width: 0px;

    &:hover {
        .logo {
            display: none;
        }

        .sub-left {
            display: block;
        }
        .el-button--warning {
            display: block;
        }
    }

    &.border {
        transform: translate3d(0, 0, 0);
        &:hover {
            box-shadow: 0px 8px 17px 0px rgba(255, 165, 38, 0.12);
            border: 1px solid rgba(255, 165, 38, 1);
            transform: scale(1.1);
        }
    }

    &.shadow {
        border-width: 0px;
        &:hover {
            box-shadow: 0px 8px 17px 0px rgba(0, 0, 0, 0.08);

            .name {
                color: $--color-warning;
            }
        }
    }
}
.new-img {
    position: absolute;
    top: 0;
    left: 10px;
    width: 30px;
    height: 30px;
}
.top {
    // padding: 26px;
    .img {
        width: 100%;
        height: 0px;
        padding-top: 71.4%;
    }
}

.name {
    font-size: 16px;
    color: $--color-text-regular;
    line-height: 26px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    span {
        vertical-align: middle;
        &.el-tag {
            margin-right: 4px;
            background-color: #ef8207;
            border-color: #ef8207;
            padding: 0 2px;
            font-size: 14px;
            font-weight: normal;
        }
    }
}

.sub {
    font-size: 13px;
    color: #878d99;
    line-height: 24px;
    margin-top: 8px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    margin-bottom: 10px;
    min-height: 48px;
}

.el-card {
    cursor: pointer;
}

.info {
    padding: 14px 16px;
}

.tips {
    font-size: 12px;
    color: rgba(255, 255, 255, 1);
    line-height: 17px;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.26);
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    white-space: nowrap;
}
.time {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0 0;
    border-top: 1px solid #f2f3f5;

    img {
        width: 20px;
        height: 20px;
        display: block;
    }

    span {
        font-size: 13px;
        color: rgba(172, 179, 191, 1);
        line-height: 24px;
    }
}
</style>
