<template>
    <div class="clockList">
        <ClockItem v-for="(item, index) in vals" :key="index" :type="item.type">
            <slot>{{ item.val }}</slot>
        </ClockItem>
    </div>
</template>
<script>
import ClockItem from './ClockItem';
export default {
    name: 'Clock',
    components: {
        ClockItem
    },
    props: {
        val: {
            type: Number | String
        }
    },
    computed: {
        vals() {
            let money = this.val || '0';
            return money.split('').map(item => {
                if (item === '.' || item === ',') {
                    return { val: item, type: 'mark' };
                } else {
                    return { val: item, type: 'number' };
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.clockList {
    display: flex;
    align-items: center;
    .Clock {
        margin: 0 2px;
    }
}
</style>
