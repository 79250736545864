<template>
    <div @click="goDetail">
        <el-card :body-style="{ padding: '0px' }">
            <div class="top">
                <div
                    class="img bg"
                    :style="{
                        backgroundImage: `url(${info.logo})`
                    }"
                ></div>
            </div>
            <div class="info">
                <div class="name">
                    {{ name }}
                </div>
            </div>
        </el-card>
    </div>
</template>
<script>
export default {
    name: 'PartnerGride',
    props: {
        info: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },
    computed: {
        name() {
            return this.$i18n.locale.indexOf('zh') != -1 ? this.info.chCompanyName : this.info.enCompanyName;
        }
    },
    methods: {
        goDetail() {
            this.$router.push('/brandDetail?id=' + this.info.id);
        }
    }
};
</script>
<style lang="scss" scoped>
@import '../../styles/variables';

.el-card {
    transition: all ease-in-out 0.3s;
    box-shadow: none;
    position: relative;
    border-width: 0px;
    transform: translate3d(0, 0, 0);

    &:hover {
        box-shadow: 0px 8px 17px 0px rgba(255, 165, 38, 0.12);
        border: 1px solid rgba(255, 165, 38, 1);
        transform: scale(1.1);

        .logo {
            display: none;
        }

        .sub-left {
            display: block;
        }
        .el-button--warning {
            display: block;
        }
    }
}
.top {
    // padding: 26px;
    .img {
        width: 100%;
        height: 0px;
        padding-top: 100%;
        background-size: contain;
        background-repeat: no-repeat;
    }
}

.el-button--warning {
    width: 95%;
    display: none;
    margin: 20px auto 10px;
}

.name {
    font-size: 15px;
    color: $--color-text-regular;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.sub {
    font-size: 12px;
    color: rgba(135, 141, 153, 1);
    line-height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 4px;

    .sub-left {
        width: 24%;
        margin-right: 5px;
        display: none;
    }
}

.el-card {
    cursor: pointer;
}
.logo-img {
    width: 100%;
    padding-top: 43%;
    height: 0;
    filter: blur(4px);
}
.logo {
    margin: 12px 18% 0;
    position: relative;
}

.info {
    padding: 10px;
}

.tips {
    font-size: 12px;
    color: rgba(255, 255, 255, 1);
    line-height: 17px;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.26);
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    white-space: nowrap;
}
</style>
